<template>
  <div>
      <b-dropdown
              size="sm"
              variant="link"
              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              no-caret
              right
              no-flip
      >
          <template v-slot:button-content>
              <v-btn class="btn btn-primary mr-16" x-large dark>Options</v-btn>
          </template>
          <!--begin::Navigation-->
          <div class="navi navi-hover min-w-md-250px">
              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'view-candidate-timetable')">
                  <a class="navi-link" @click="previewCandidates(entrance.id)">
                                                  <span class="navi-icon" >
                                                     <i class="fas fa-file-pdf"></i>
                                                  </span>
                      <span class="navi-text">Candidate timetable</span>
                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item"
                               v-if="checkIsAccessible('entrance-exam', 'notify-exam') && entrance.is_active && entrance.total_verified_candidates > 0 && entrance.is_confirmed">
                  <a href="#" @click="notifyExam(entrance.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-envelope-open"></i>
                                </span>
                      <span class="navi-text"> Notify exam confirmation via email</span>
                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item"
                               v-if="checkIsAccessible('entrance-exam', 'exam-conducted') && entrance.is_active  && !entrance.is_conducted && entrance.is_confirmed">
                  <a href="#"
                     @click="markAsConducted(entrance.id)"
                     class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-check-circle"></i>
                                </span>
                      <span class="navi-text">Mark as conducted</span>
                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item"
                               v-if="checkIsAccessible('entrance-exam', 'result-published') && entrance.is_active && !entrance.result_published && entrance.is_confirmed && entrance.is_conducted">
                  <a href="#" @click="markAsResultPublished(entrance.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-calculator"></i>
                                </span>
                                <span class="navi-text">
                                  Mark as result published
                                </span>
                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'notify-result') && entrance.is_active && entrance.is_confirmed && entrance.is_conducted && entrance.result_published">
                  <a href="#" @click="notifyResult(entrance.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-envelope-open"></i>
                                </span>
                      <span class="navi-text"> Notify result via email</span>
                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item"
                               v-if="checkIsAccessible('entrance-exam', 'set-result') && entrance.is_active && entrance.is_confirmed && entrance.is_conducted">
                  <router-link
                          :to="{
                                  name: 'entrance-exam-set-result',
                                  params: { id: entrance.id },
                                }"
                          class="navi-link"
                  >
                                <span class="navi-icon">
                                  <i class="fa fa-book-reader"></i>
                                </span>
                      <span class="navi-text"> Set result</span>
                  </router-link>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'exam-confirmed') && entrance.is_active">
                  <a href="#"
                          @click="markAsConfirmed(entrance.id)"
                          class="navi-link">
                                <span class="navi-icon" v-if="!entrance.is_confirmed">
                                  <i class="fa fa-check-circle-o"></i>
                                </span>
                      <span class="navi-icon" v-if="entrance.is_confirmed">
                                  <i class="fas fa-close"></i>
                                </span>
                      <span class="navi-text">
                                  {{ !entrance.is_confirmed ? "Mark as confirmed" : "Mark as not confirmed" }}
                                </span>
                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item" v-if="entrance.is_expired">
                  <a href="#" class="navi-link"
                          @click="editEntrance(entrance.id)">
                                <span class="navi-icon">
                                  <i class="flaticon-edit"></i>
                                </span>
                      <span class="navi-text"> Edit </span>
                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'import-result-excel') && entrance.is_active && entrance.is_confirmed && entrance.is_conducted">
                  <a href="#" @click="openAndCloseImportResultDialog(entrance.id)" class="navi-link">
                                                                   <span class="navi-icon">
                                                                      <i class="fa fa-file-export"></i>
                                                                    </span>
                      <span class="navi-text"> Import result from excel </span>

                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'download-result-excel') && entrance.is_active && entrance.is_confirmed && entrance.is_conducted">

                  <a href="#" @click="downloadImportCsv(entrance.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-file-excel"></i>
                                </span>
                      <span class="navi-text"> Download sample excel for result </span>
                  </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('entrance-exam', 'notify-bulk-orientation-bulk') && entrance.is_active && entrance.is_confirmed && entrance.is_conducted">
                  <a href="#" @click="notifyOrientationInBulk(entrance)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-envelope-open"></i>
                                </span>
                      <span class="navi-text"> Notify orientation via email</span>
                  </a>
              </b-dropdown-text>
          </div>
      </b-dropdown>
      <v-dialog v-model="result_dialog" persistent max-width="600px">
          <v-card>
              <v-toolbar dark>
                  <v-card-title class="headline">
                      Import result
                      <hr>
                  </v-card-title>

                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-btn icon  @click="closeResultDialog">
                          <i class="fas fa-times"></i>
                      </v-btn>
                  </v-toolbar-items>
              </v-toolbar>

              <v-card-text>
                  <v-row>
                      <v-col cols="12">
                          <v-file-input v-model="import_file"
                                        type="file"
                                        outlined dense :error="$v.import_file.$error"
                                        label="Import user enrollment result"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          ></v-file-input>
                          <span class="text-danger" v-if="$v.import_file.$error">
                    Please select a csv file to import result
                  </span>
                      </v-col>
                  </v-row>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          class="btn btn-standard cancel-btn"
                          depressed
                          @click="closeResultDialog">Cancel</v-btn>
                  <v-btn :loading="import_loading"
                          class="text-white ml-2 btn btn-primary"
                          depressed
                          @click.prevent="importResult"
                  >Import result</v-btn
                  >

              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>
<script>
import EntranceExamService from "@/core/services/entrance-exam/EntranceExamService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
const enrollmentService = new UserEnrollmentService();
import { required, requiredIf } from "vuelidate/lib/validators";
import {API_URL} from "@/core/config";
const entranceService = new EntranceExamService();
export default {
    validations: {
        import_file: { required },

    },
    props:['entrance','actionloading'],
    data(){
      return{
          import_file: null,
          result_dialog: null,
          entranceId: null,
          import_loading:false,

      }
    },
    methods:{
        previewCandidates(entranceId){
            entranceService
                .previewAllCandidatesPDF(entranceId)
        },
        notifyExam(id) {
            this.$confirm({
                message: `Do you wish to continue notifying all applications about their entrance exam via email?`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.actionloading=true;
                        entranceService
                            .notifyExam(id)
                            .then((response) => {
                                this.$snotify.success("Candidates notified");
                                this.$emit('refresh-entrance');
                            })
                            .catch((err) => {
                                this.$snotify.error(
                                    "Something went wrong. Please try again later!!"
                                );
                            })
                            .finally(() => {
                                this.actionloading=false;
                            })
                    }
                },
            });
        },
        markAsConducted(id) {
            this.$confirm({
                message: `Are you sure you want to mark the exam status as conducted?`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.actionloading=true;
                        entranceService
                            .examConducted(id)
                            .then((response) => {
                                this.$snotify.success("Exam status changed");
                                this.$emit('refresh-entrance');
                            })
                            .catch((err) => {
                                this.$snotify.error(
                                    "Something went wrong. Please try again later!!"
                                );
                            })
                            .finally(() => {
                                this.actionloading=false;
                            })
                    }
                },
            });
        },
        markAsResultPublished(id) {
            this.$confirm({
                message: `Are you sure you want to set result status to publish?`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.actionloading=true;
                        entranceService
                            .resultPublished(id)
                            .then((response) => {
                                this.$snotify.success("Result published");
                                this.$emit('refresh-entrance');
                            })
                            .catch((err) => {
                                this.$snotify.error(
                                    "Something went wrong. Please try again later!!"
                                );
                            })
                            .finally(() => {
                                this.actionloading=false;
                            })
                    }
                },
            });
        },
        notifyResult(id) {
            this.$confirm({
                message: `Are you sure you want to notify this exam result?`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.actionloading=true;
                        entranceService
                            .notifyResult(id)
                            .then((response) => {
                                this.$snotify.success("Result notified successfully");
                                this.$emit('refresh-entrance');
                            })
                            .catch((err) => {
                                this.$snotify.error(
                                    "Something went wrong. Please try again later!!"
                                );
                            })
                            .finally(() => {
                                this.actionloading=false;
                            })
                    }
                },
            });
        },
        markAsConfirmed(id) {
            this.$confirm({
                message: `Do you want to change confirmation status for this exam?`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.actionloading=true;
                        entranceService
                            .resultConfirmed(id)
                            .then((response) => {
                                this.$snotify.success("Status changed");
                                this.$emit('refresh-entrance');
                            })
                            .catch((err) => {
                                this.$snotify.error(
                                    "Something went wrong. Please try again later!!"
                                );
                            })
                            .finally(() => {
                                this.actionloading=false;
                            })
                    }
                },
            });
        },
        editEntrance(id) {
            this.$router.push({ name: "entrance-exam-update", params: { id: id } });
        },


        downloadImportCsv(entranceId) {
            let url = "";
            let data = {entrance_id: entranceId}
            var queryString = Object.keys(data)
                .map(key => {
                    return (encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
                }).join("&");
            if (queryString) {
                url = url + "?" + queryString;
            }
            url = API_URL + 'user/download/import/entrance-exam/result' + url;
            window.open(
                url,
                "_blank"
            );
        },
        openAndCloseImportResultDialog(entranceExamId) {
            this.import_file = null;
            this.entranceId=entranceExamId;
            if (!this.result_dialog) {
                this.result_dialog = true;
            } else {
                this.result_dialog = true;
            }
            this.$v.import_file.$reset();
        },
        closeResultDialog(){
            this.$v.import_file.$reset();
            this.entranceId=null;
            this.result_dialog = false;
        },
        importResult() {
            this.$v.import_file.$touch();
            if (this.$v.import_file.$error) {
                setTimeout(() => {
                    this.$v.import_file.$reset();
                }, 3000);
            } else {
              this.import_loading=true;
                this.actionloading=true;
                let fd = new FormData();
                fd.append("file", this.import_file);
                fd.append("entrance_id", this.entranceId);
                enrollmentService
                    .importResult(fd)
                    .then((response) => {

                        this.result_dialog = false;
                        this.import_file = null;
                        this.$v.import_file.$reset();
                        this.$snotify.success("Information imported");
                        this.entranceId=null;
                    })
                    .catch((error) => {

                        this.$snotify.error("Something went wrong. Please try again later");
                    })
                    .finally(() => {
                        this.actionloading=false;
                      this.import_loading=false;
                    })
            }
        },
        notifyOrientationInBulk(entranceExam){
            if(entranceExam.has_orientation_date){
                this.$confirm({
                    message: `Are you sure you want to notify upcoming orientation to candidate ?`,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    callback: (confirm) => {
                        if (confirm) {
                            this.actionloading=true;
                            entranceService
                                .notifyOrientationInBulk(entranceExam.id)
                                .then((response) => {
                                    this.$snotify.success("Orientation invitation sent successfully via email");
                                    this.$emit('refresh-entrance');
                                })
                                .catch((err) => {
                                    this.$snotify.error(
                                        "Something went wrong. Please try again later!!"
                                    );
                                })
                                .finally(() => {
                                    this.actionloading=false;
                                })
                        }
                    },
                });
            }else{
                this.$snotify.error(
                    "Orientation date has not configured"
                );
            }

        }
    },
}
</script>