<template>
  <v-dialog v-model="dialog" persistent max-width="800px">

    <v-card>
      <v-card-title>Upload admission documents</v-card-title>
      <v-card-text>
        <!--        <pre>{{ selected_entrance_date }}</pre>-->
        <!--        <pre>{{ selected_entrance_time }}</pre>-->
        <!--        <pre>{{ enrollment_id }}</pre>-->
        <v-row>
          <div class="col-12">
            <v-file-input outlined dense  prepend-inner-icon="mdi-file"
                          prepend-icon="" v-model="marksheet" label="Marksheet"></v-file-input>
          </div>
          <div class="col-12">
            <h5>Additional documents</h5>
          </div>
          <div class="col-4">
            <v-text-field outlined dense v-model="document_one_title" label="Attachment one title"></v-text-field>
          </div>
          <div class="col-8">
            <v-file-input outlined dense v-model="document_one" label="Attachment one" prepend-inner-icon="mdi-file"
                          prepend-icon=""></v-file-input>
          </div>
          <div class="col-4">
            <v-text-field outlined dense v-model="document_two_title" label="Attachment two title"></v-text-field>
          </div>
          <div class="col-8">
            <v-file-input outlined dense v-model="document_two" label="Attachment two" prepend-inner-icon="mdi-file"
                          prepend-icon=""></v-file-input>
          </div>
          <div class="col-4">
            <v-text-field outlined dense v-model="document_three_title" label="Attachment three title"></v-text-field>
          </div>
          <div class="col-8">
            <v-file-input outlined dense v-model="document_three" label="Attachment three " prepend-inner-icon="mdi-file"
                          prepend-icon=""></v-file-input>
          </div>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn btn-standard" @click="closeDialog" depressed>Cancel</v-btn>
        <v-btn :loading="loading" class="btn btn-primary text-white" depressed @click.prevent="validateUploads">Upload</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import {required} from "vuelidate/lib/validators";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";

const enrollmentService = new UserEnrollmentService();
export default {
  name: "UploadDocument",
  props: ['dialog', 'enrollment_id'],
  validations: {
    photo: {},
  },
  data() {
    return {
      photo: null,
      loading: false,
      marksheet: null,
      document_one: null,
      document_two: null,
      document_three: null,
      document_one_title: null,
      document_two_title: null,
      document_three_title: null,
    }
  },
  methods: {
    validateUploads() {
      this.$v.photo.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.photo.$reset();
        }, 5000)
      } else {
        let fd = this.buildFormData();
        this.loading = true
        enrollmentService.uploadFiles(this.enrollment_id, fd).then(response => {
          this.displayMessage("success");
          this.loading = false
          this.closeDialog();
        }).catch(error => {
          this.loading = false
          this.displayMessage("error");
        })
      }
    }, buildFormData() {
      let fd = new FormData();
      // if (this.photo && this.photo != undefined && this.photo != null) {
      //   fd.append('photo', this.photo);
      // }
      if (this.marksheet && this.marksheet != undefined && this.marksheet != null) {
        fd.append('marksheet', this.marksheet);
      }
      if (this.document_one && this.document_one != undefined && this.document_one != null) {
        fd.append('file_name[]', this.document_one_title);
        fd.append('file[]', this.document_one);
      }
      if (this.document_two && this.document_two != undefined && this.document_two != null) {
        fd.append('file_name[]', this.document_two_title);
        fd.append('file[]', this.document_two);
      }
      if (this.document_three && this.document_three != undefined && this.document_three != null) {
        fd.append('file_name[]', this.document_three_title);
        fd.append('file[]', this.document_three);
      }
      return fd;
    },
    closeDialog() {
      this.photo = null,
          this.marksheet = null,
          this.document_one = null,
          this.document_two = null,
          this.document_three = null,
          this.document_one_title = null,
          this.document_two_title = null,
          this.document_three_title = null,
          this.$v.photo.$reset();
          // this.dialog=false;
      this.$emit("close_document_dialog")
    },
      setSelectedCandidate(selected_enrolment) {
      this.selected_enrolment = selected_enrolment
    }, entranceDateAndTimeSet(date, time) {
      this.selected_entrance_date = date;
      this.selected_entrance_time = time;
    },
  }
}
</script>

<style scoped>
  .camera-box {
    border: 1px dashed #d6d6d6;
    border-radius: 4px;
    padding: 2px;
    width: 80%;
    min-height: 300px;
  }
</style>