<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="1000">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Upload applicant photo
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <h6><strong>Enrolment Full name</strong> {{ enrollment.full_name}}</h6>
                            <h6><strong>Enrolment Symbol No.</strong> {{ enrollment.symbol_no ? enrollment.symbol_no : "#####" }}</h6>
                        </v-col>

                        <v-col cols="12">
                            <strong>
                                <v-switch
                                    @change="changeWebcamStatus"
                                    v-model="useWebcam"
                                    :label="useWebcam ? 'Camera' : 'Upload'"
                                ></v-switch>

                            </strong>
                        </v-col>
                        
                        <v-col cols="12" v-if="!useWebcam">
                            <v-file-input 
                                outlined 
                                dense 
                                v-model="photo" 
                                prepend-inner-icon="mdi-file"
                                prepend-icon="" 
                                @change="previewUploadedFile"
                                label="Applicant photo" 
                                :accept="'image/jpeg,image/png'"
                                :error="$v.photo.$error"
                            ></v-file-input>
                            <span class="text-danger" v-if="$v.photo.$error">Applicant Photo is required</span>
                        </v-col>

                        <v-col cols="12" md="4" v-if="!useWebcam">
                            <div class="">
                                <img v-if="!croppedPic" :src="previewCapturedPhoto" class="rounded" style="max-width: 100%; object-fit:contain;" alt="">
                            </div>
                            <div v-if="cropUploadFile">
                                <img v-if="croppedPic" :src="croppedPic" class="mt-4 border rounded" style="border:6px solid #efefef; max-width: 100%; object-fit:contain;" alt="Cropped Image">
                            </div>
                        </v-col>

                        <!-- crop uploaded image -->
                        <v-col cols="12" md="8" v-if="!useWebcam">
                            <div v-if="previewCapturedPhoto">
                                <v-btn
                                    class="text-white"
                                    color="black"
                                    small
                                    @click="openCropperForFileUpload"
                                >
                                    {{ cropUploadFile ? 'Cancel' : 'Crop' }}
                                </v-btn>

                                <v-btn
                                    v-if="cropUploadFile"
                                    class="ml-3 text-white"
                                    color="green"
                                    small
                                    @click="cropImage"
                                >
                                    Save selection
                                </v-btn>
                            </div>
                            <div v-if="cropUploadFile" class="mt-4">
                                <cropper
                                    class="cropper"
                                    :src="previewCapturedPhoto"
                                    @crop="cropImage"
                                    v-model="croppedPic"
                                    :output-image-quality="0.7"
                                    :output-image-type="'jpeg'"
                                    :output-max-height="800"
                                    :output-max-width="800"
                                    ref="cropper"
                                />
                            </div>
                        </v-col>
                        <!-- end crop uploaded image -->

                        <!-- webcam section -->
                        <v-col v-if="useWebcam" cols="12" md="5">
                            <div class="camera-box py-3 mt-20">
                                <div style="display: flex; justify-content: center;">
                                    <img 
                                        style="height: 25px;" 
                                        v-if="isCameraOpen"
                                        src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
                                        class="button-img camera-shoot" 
                                        @click="capture"
                                    />
                                    <div class="camera-button">
                                        <button 
                                            type="button" 
                                            class="button is-rounded cam-button"
                                            style="margin-left: 40%; background-color: white; border: 0px;"
                                            @click="toggleCamera"
                                        >
                                        <span v-if="!isCameraOpen">
                                            <img 
                                                style="height: 25px;" 
                                                class="button-img"
                                                src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
                                            >
                                            </span>
                                            <span v-else>
                                            <img style="height: 25px;" class="button-img"
                                                            src="https://img.icons8.com/material-outlined/50/000000/cancel.png">
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div style="height: 250px" class="text-center">
                                    <div v-if="isCameraOpen" class="camera-canvas my-5">
                                        <video ref="camera" :width="canvasWidth" :height="canvasHeight" autoplay></video>
                                        <canvas v-show="false" id="photoTaken" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
                                    </div>
                                </div>
                                <div class="mt-2 text-center">
                                    <v-btn
                                        :loading="isCapturing"
                                        @click="capture"
                                        medium
                                        v-if="isCameraOpen"
                                        class="btn btn-primary ml-3 mb-2"
                                        dark
                                    >
                                        Capture
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <!-- end webcam section -->

                        <!-- webcam cropper section -->
                        <v-col v-if="useWebcam" cols="12" md="7">
                            <cropper
                                class="cropper"
                                :src="previewCapturedPhoto"
                                @crop="cropImage"
                                v-model="croppedPic"
                                :output-image-quality="0.7"
                                :output-image-type="'jpeg'"
                                :default-size="defaultSize"
                                ref="cropper"
                            />
<!-- 120*140 -->

                            <div v-if="previewCapturedPhoto">
                                <v-btn
                                    class="mt-3 text-white"
                                    color="green"
                                    small
                                    @click="cropImage"
                                >
                                    Save selection
                                </v-btn>

                                <v-btn
                                    class="mt-3 ml-3 text-white"
                                    color="orange"
                                    small
                                    @click="recapture"
                                >
                                    Recapture
                                </v-btn>

                                <v-btn
                                    class="mt-3 ml-3 text-white"
                                    color="red"
                                    small
                                    v-if="croppedPic"
                                    @click="resetCroppedImage"
                                >
                                    Reset selection
                                </v-btn>
                            </div>

                            <div>
                                <img v-if="croppedPic" :src="croppedPic" class="mt-4 border rounded" style="border:6px solid #efefef;" alt="Cropped Image">
                            </div>
                        </v-col>
                        <!-- end webcam cropper section -->

                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="btn btn-standard" @click="hideModal" depressed>Cancel</v-btn>
                    <v-btn :disabled="useWebcam && !capturedPhotoFile" :loading="loading" class="btn btn-primary text-white" depressed @click.prevent="validateUploads">Upload</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>


<script>
import {required} from "vuelidate/lib/validators";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
const enrollmentService = new UserEnrollmentService();

export default {
    validations: {
        photo: {required},
    },
    components: {
		Cropper,
	},
    data(){
        return {
            dialog: false,
            enrollment: '',
            loading: false,
            photo: null,
            useWebcam: true,
            image: null,
            isCameraOpen: false,
            canvasHeight:250,
            canvasWidth:300,
            capturedPhotoFile: null,
            previewCapturedPhoto: null,
            isCapturing: false,
            croppedPic: null,
            coordinates: {
				width: 0,
				height: 0,
				left: 0,
				top: 0,
			},
            cropUploadFile: false,
        }
    },
    methods:{
        defaultSize() {
			return {
				width: 160,
				height: 180,
			};
		},
        hideModal(){
            this.dialog = false;
            this.resetForm();
        },
        showModal(enrollment){
            this.dialog = true;
            this.enrollment = enrollment;
            if(this.enrollment.user_document_photo){
                this.previewCapturedPhoto = this.enrollment.user_document_photo.real;
            }else{
                this.previewCapturedPhoto = null;
            };
        },
        previewUploadedFile(){
            if(this.photo){
                if(this.isFileTypeValid()){
                    this.previewCapturedPhoto= URL.createObjectURL(this.photo);
                }
                else{
                    this.$snotify.error('Please enter valid image. Only JPEG and PNG files are allowed');
                    this.photo = null;
                }
            }else{
                this.previewCapturedPhoto=null;
                this.photo = null;
            }
        },
        validateUploads() {
            
            if(!this.useWebcam){
                this.$v.photo.$touch()
            }

            if (this.$v.$error) {
                setTimeout(() => {
                    this.$v.photo.$reset();
                }, 5000)
            } else {
                let fd = '';
                
                if(!this.useWebcam){
                    fd = new FormData()
                    if(this.cropUploadFile && !this.croppedPic){
                        this.$snotify.error('Please save the cropped image');
                        return false;
                    }
                    else if(this.cropUploadFile && this.croppedPic){
                        fd.append('photo', this.capturedPhotoFile);
                    }
                    else{
                        fd.append('photo', this.photo);
                    }
                }else{
                    fd = new FormData()
                    fd.append('photo', this.capturedPhotoFile)
                }
                
                this.loading = true
                enrollmentService.uploadFiles(this.enrollment.id, fd).then(response => {
                    this.displayMessage("success");
                    this.loading = false
                    this.hideModal();
                    this.toggleCamera();
                    this.previewCapturedPhoto = null;
                    this.resetForm();
                    this.photo = null;
                    this.resetCroppedImage();
                    this.$emit('refresh');
                    this.$v.$reset();
                }).catch(error => {
                    this.loading = false
                    this.displayMessage("error");
                })
            }
        }, 
        resetForm(){
            this.photo = null;
            this.useWebcam = true;
            this.image = null;
            this.isCameraOpen = false;
            this.canvasHeight =250;
            this.canvasWidth =300;
            this.capturedPhotoFile = null;
            this.previewCapturedPhoto = null;
            this.isCapturing = false;        
        },
        buildFormData() {
            let fd = new FormData();
            if (this.photo && this.photo != undefined && this.photo != null) {
                fd.append('photo', this.photo);
            }
            return fd;
        },

        takePhoto(){
            this.useWebcam =  !this.useWebcam;
        },

        recapture(){
            this.toggleCamera();
            this.previewCapturedPhoto = null;
            this.capturedPhotoFile = null;
			this.croppedPic = null;
        },

        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.startCameraStream();
                this.resetCroppedImage();
                this.previewCapturedPhoto = null;
            }
        },

        startCameraStream() {
            const constraints = (window.constraints = {
                audio: false,
                video: true
            });
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.$refs.camera.srcObject = stream;
                }).catch(error => {
                // alert("Browser doesn't support or there is some errors." + error);
            });
        },

        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            tracks.forEach(track => {
                track.stop();
            });
        },

        capture() {
            const FLASH_TIMEOUT = 50;
            let self = this;
            this.isCapturing = true;
            setTimeout(() => {
                const canvas = self.$refs.canvas;
                const context = self.$refs.canvas.getContext('2d');
                context.drawImage(self.$refs.camera, 0, 0, self.canvasWidth, self.canvasHeight);
                const dataUrl = self.$refs.canvas.toDataURL("image/jpeg")
                    .replace("image/jpeg", "image/octet-stream");
                
                self.previewCapturedPhoto = dataUrl;
                self.uploadPhoto(dataUrl);
                self.isCameraOpen = false;
                self.stopCameraStream();
                
            }, FLASH_TIMEOUT);
            this.isCapturing = false;
        },

        dataURLtoFile(dataURL, filename) {
            let arr = dataURL.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
        
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type: mime});
        },
        
        uploadPhoto(dataUrl){
            this.capturedPhotoFile = this.dataURLtoFile(dataUrl, Math.random().toString(36).substring(2, 15)+'.jpg')
        },

        cropImage() {
            const { coordinates, canvas, } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;
			this.croppedPic = canvas.toDataURL();
            this.capturedPhotoFile = this.dataURLtoFile(this.croppedPic, Math.random().toString(36).substring(2, 15)+'.jpg')
        },

        resetCroppedImage(){
            this.capturedPhotoFile = null;
			this.croppedPic = null;
        },

        changeWebcamStatus(){
            this.previewCapturedPhoto = null;
            this.photo = null;
            this.cropUploadFile = false;
            this.resetCroppedImage();
        },

        openCropperForFileUpload(){
            this.cropUploadFile = !this.cropUploadFile;
        },

        isFileTypeValid() {
            if (!this.photo) {
                return true;
            }
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if(allowedTypes.includes(this.photo.type)){
                return true;
            }else{
                return false;
            }
        }
    }
}
</script>

<style scoped>
  .camera-box {
    border: 1px dashed #d6d6d6;
    border-radius: 4px;
    padding: 2px;
    width: 100%;
    min-height: 100px;
  }
  .captured-img{
    width:30%;
  }

  .cropper {
        /* height: 600px; */
        width: 100%;
        object-fit: contain;
        background: #DDD;
    }
</style>