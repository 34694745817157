<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        width="1000" persistent
        transition="dialog-bottom-transition"
    >
      <v-card v-if="enrollment">
        <v-toolbar dark>
          <v-card-title class="headline">
            Enrolment information for {{enrollment.full_name}} <span class="badge badge-warning" v-if="!enrollment.is_submitted">Draft</span>
              <span v-if="enrollment.is_approved">
                             <v-chip small
                                     class="ma-2"
                                     color="green"
                                     text-color="white"
                             ><i class="fa fa-check-circle mr-2 text-white"></i> Verified</v-chip>
                        </span>
              <span v-if="!enrollment.is_approved" >
                             <v-chip small
                                     class="ma-2"
                                     color="red"
                                     text-color="white"
                             ><i class="fa fa-close mr-2 text-white"></i>Not verified</v-chip>
                        </span>
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>


        <v-card-text class="enrolment_form_detail_admin">
          <v-tabs>
            <v-tab>
              Personal
            </v-tab>
            <v-tab>
                Address
            </v-tab>
            <v-tab>
                Parent
            </v-tab>
            <v-tab>
              Education
            </v-tab>
            <v-tab>
              Entrance
            </v-tab>
              <v-tab>
                  Documents
              </v-tab>
              <v-tab>
                  Other
              </v-tab>
              <v-tab v-if="enrollment.is_paid && enrollment.is_submitted && enrollment.is_approved && enrollment.invoice_number">
                  Invoice
              </v-tab>
            <v-tab-item>
              <v-card><br>
                <v-row class="bg-highlight-title">
                  <v-col cols="12" >
                    <h5>Personal details  </h5><hr/>
                  </v-col>
                  <v-col cols="9">
                      <p class="mb-2"><strong> Full name</strong> {{ enrollment.full_name }}</p>
                      <p class="mb-2"><strong> Gender</strong> {{ enrollment.gender }}</p>
                      <p class="mb-2"><strong> Phone</strong> {{ enrollment.phone }}</p>
                      <p class="mb-2"><strong> Email</strong> {{ enrollment.email!='null'?enrollment.email:'NA' }}</p>
                      <p class="mb-2"><strong> Nationality </strong>{{ enrollment.nationality }}</p>
                      <p class="mb-2"><strong> Date of birth (AD) </strong> <span v-if="enrollment.dob">{{ enrollment.dob }}</span> <span v-else>NA</span></p>
                      <p class="mb-2"><strong> Date of birth (BS) </strong> <span v-if="enrollment.dob_bs">{{ enrollment.dob_bs }}</span> <span v-else>NA</span></p>
                      <p class="mb-2"><strong> Submission date </strong> <span v-if="enrollment.submitted_date">{{ enrollment.submitted_date }}</span> <span v-else>NA</span></p>
                  </v-col>
                  <v-col cols="3"  v-if="enrollment.user_document_photo">
                      <img
                              :src="enrollment.user_document_photo"
                              alt
                              @click="openImage(photo)"
                              class="img-thumbnail" style="width:250px; height:200px; cursor: pointer"
                      />
                  </v-col>
                </v-row>

              </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card>
                    <v-row class="bg-highlight-title">
                        <v-col cols="12">
                            <h5>Permanent address</h5><hr/>
                        </v-col>
                        <v-col cols="12">
                            <template v-if="!enrollment_details">
                                <v-skeleton-loader
                                        v-bind="attrs"
                                        type="list-item-three-line, card-heading"
                                ></v-skeleton-loader>
                            </template>
                            <template v-else>
                                <v-row v-if="enrollment">
                                    <v-col md="6" v-if="basic_info && basic_info.country">
                                        <p ><strong> Country</strong> {{ basic_info.country ? ucwords(basic_info.country) : 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong> Province</strong> {{ enrollment.province_name || 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong> District name</strong> {{ enrollment.district_name || 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong> Local level</strong> {{ enrollment.municipality_name || 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong>Ward</strong> {{ enrollment.ward_name || 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong>Area/tole</strong> {{ enrollment.area_title || 'N/A' }}</p>

                                        <hr>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col cols="12">
                                        <strong>N/A</strong>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row class="bg-highlight-title" v-if="basic_info && basic_info.temporary_country">
                        <v-col cols="12">
                            <h5>Temporary address</h5><hr/>
                        </v-col>
                        <v-col cols="12">
                            <template v-if="!enrollment_details">
                                <v-skeleton-loader
                                        v-bind="attrs"
                                        type="list-item-three-line, card-heading"
                                ></v-skeleton-loader>
                            </template>
                            <template v-else>
                                <v-row v-if="enrollment">
                                    <v-col md="6" v-if="basic_info && basic_info.country">
                                        <p ><strong> Country</strong> {{ basic_info.temporary_country ? ucwords(basic_info.temporary_country) : 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong> Province</strong> {{ enrollment.temporary_province_name || 'N/A'}}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong> District name</strong> {{ enrollment.temporary_district_name || 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong> Local level</strong> {{ enrollment.temporary_municipality_name  || 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong>Ward</strong> {{ enrollment.temporary_ward_name || 'N/A' }}</p>
                                    </v-col>
                                    <v-col md="6">
                                        <p ><strong>Area/tole</strong> {{ enrollment.temporary_area_title || 'N/A' }}</p>

                                        <hr>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col cols="12">
                                        <strong>N/A</strong>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card>
                    <v-row class="bg-highlight-title">
                        <v-col cols="12">
                            <h5>Parents details</h5><hr/>
                        </v-col>
                        <v-col cols="6">
                            <strong>Guardian's name</strong> : {{ basic_info && basic_info.guardian_name ? basic_info.guardian_name : 'N/A' }}
                        </v-col>
                        <v-col cols="6" >
                            <strong>  Guardian's contact </strong> : {{ basic_info && basic_info.guardian_contact_number ? basic_info.guardian_contact_number : 'N/A' }}
                        </v-col>
                        <v-col cols="6">
                            <strong> Father name</strong> : {{ basic_info && basic_info.father_name ? basic_info.father_name : 'N/A' }}
                        </v-col>
                        <v-col cols="6" >
                            <strong>  Father's contact </strong> : {{ basic_info && basic_info.father_contact_number ? basic_info.father_contact_number : 'N/A' }}
                        </v-col>
                        <v-col cols="6" >
                            <strong> Mother name</strong> : {{ basic_info && basic_info.mother_name ? basic_info.mother_name : 'N/A' }}
                        </v-col>
                        <v-col cols="6" >
                            <strong> Mother's contact</strong> : {{ basic_info && basic_info.mother_contact_number ? basic_info.mother_contact_number : 'N/A' }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-row class="bg-highlight-title">
                  <v-col cols="12">
                    <h5>Educational information</h5>
                  </v-col>
                  <v-col cols="12" v-if="educational_information">
                      <div style="overflow-x:auto;">
                        <table style="width: 100%; border-collapse: collapse;">
                            <thead>
                              <th>Name</th>
                              <th>Institute name</th>
                              <th>Exam board</th>
                              <th>Completed year</th>
                              <th>GPA</th>
                              <th>Symbol No</th>
                            </thead>
                            <tbody>
                              <tr v-show=" educational_information.academic_backgrounds.length > 0" v-for="(academic,i) in enrollment.customize_user_academic_backgrounds"
                                    :key="i">
                                  <td >
                                      {{ ucwords(academic.name) || 'N/A' }}</td>
                                  <td>
                                      <div>
                                          {{academic.institute_name }}
                                      </div>
                                      <div>

                                          <small v-if="academic.address" class="font-weight-medium" v-html="academic.address"></small>

                                      </div>

                                  </td>
                                  <td >{{academic.exam_board }}</td>
                                  <td >{{academic.completed_year }}</td>
                                  <td  >{{academic.gpa }}</td>
                                  <td  >{{academic.symbol_no || 'N/A' }}</td>
                              </tr>
                              <tr v-if="educational_information.academic_backgrounds.length == 0">
                                <td colspan="6" class="text-center">
                                  No items found
                                </td>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                  </v-col>
                  <v-col md="12"  v-if="enrollment && educational_information && enrollment.compulsory_courses">
                      <label for="" class="form-label">Compulsory courses</label>
                      <span class="form-control mt-4" >
                          <span class="badge badge-compulsary-course" v-for="(compulsory_course,index) in enrollment.compulsory_courses" :key="index">{{compulsory_course}}</span>
                      </span>
                  </v-col>
                    <v-col md="12" v-if="educational_information">
                        <label for="" class="form-label">Selected courses</label>
                        <span class="form-control mt-3">
                            <span class="badge badge-course" v-if="educational_information.selectable_course_1">{{educational_information.selectable_course_1}}</span>
                            <span class="ml-3 badge badge-course" v-if="educational_information.selectable_course_2">{{educational_information.selectable_course_2}}</span>
                            <span class="ml-3 badge badge-course" v-if="educational_information.selectable_course_3">{{educational_information.selectable_course_3}}</span>
                            <span class="ml-3 badge badge-course" v-if="educational_information.selectable_course_4">{{educational_information.selectable_course_4}}</span>
                        </span>
                    </v-col>
                    <v-col md="12" v-if="shifts">
                        <div class="form-group">
                            <label for="" class="form-label">Shift</label>
                            <span class="form-control mt-3">
                              <span class="badge badge-info">
                                  {{ucwords(shifts)}}
                              </span>
                            </span>
                        </div>
                    </v-col>

                </v-row>
              </v-card>

            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-row class="bg-highlight-title">
                    <v-row>
                        <v-col md="6">
                            <v-col cols="12">
                                <h5>Entrance date & time</h5><hr/>
                            </v-col>
                            <v-col cols="12" >
                                <strong>Entrance date</strong>
                                <span class=" "> {{ enrollment.entrance_date || 'N/A'}} </span>
                            </v-col>
                            <v-col cols="12" >
                                <strong>Entrance time</strong>
                                <span class=""> {{ enrollment.entrance_start_time || 'N/A' }} </span>
                            </v-col>
                            <v-col cols="12" >
                                <strong>Entrance symbol</strong>
                                <span class=""> {{ enrollment.symbol_no ? enrollment.symbol_no : "NA" }} </span>
                            </v-col>
                            <v-col cols="12" >
                                <strong>Entrance PIN</strong>
                                <span class=""> {{ enrollment.enrolment_pin ? enrollment.enrolment_pin : "NA" }} </span>
                            </v-col>
                            <hr>
                            <v-col cols="12" >
                                <strong>Entrance exam building</strong>
                                <span class=""> {{ enrollment.building_desc ? enrollment.building_desc : "NA" }} </span>
                            </v-col>
                            <v-col cols="12" >
                                <strong>Entrance exam room</strong>
                                <span class=""> {{ enrollment.room_desc ? enrollment.room_desc : "NA" }} </span>
                            </v-col>
                            <hr>
                            <v-col cols="12">
                                <strong>Exam  notified to candidate</strong>
                                <span  class="font-weight-medium"> {{ enrollment.exam_notified_date ? 'Yes' : "No" }} </span>
                            </v-col>
                            <v-col cols="12" v-if=" enrollment.exam_notified_date">
                                <strong>Scheduled confirmation  notified date</strong>
                                <span class=""> {{ enrollment.exam_notified_date ? enrollment.exam_notified_date : "NA" }} </span>
                            </v-col>
                            <v-col cols="12">
                                <strong>Exam result notified to candidate</strong>
                                <span class="font-weight-medium"> {{ enrollment.result_notified_date ? 'Yes' : "No" }} </span>
                            </v-col>
                            <v-col cols="12" v-if=" enrollment.result_notified_date">
                                <strong>Result notified date</strong>
                                <span class=""> {{ enrollment.result_notified_date ? enrollment.result_notified_date : "NA" }} </span>
                            </v-col>
                            <hr>
                            <v-col cols="12" >
                                <strong>Mark obtained</strong>
                                <span class=""> {{ enrollment.obtained_marks ? enrollment.obtained_marks : "NA" }} </span>
                            </v-col>
                            <hr>
                            <v-col cols="12">
                                <strong>Enrolment offer accepted</strong>
                                <span class="font-weight-medium"> {{ enrollment.enrolment_offer_accepted ? 'Yes' : "N0" }} </span>
                            </v-col>
                            <v-col cols="12" v-if="enrollment.format_enrolment_offer_accepted_datetime">
                                <strong>Enrolment offer accepted at</strong>
                                <span class=""> {{ enrollment.format_enrolment_offer_accepted_datetime }} </span>
                            </v-col>
                            <v-col cols="12" v-if=" enrollment.academic_class_title">
                                <strong>Assigned class</strong>
                                <span class=""> {{ enrollment.academic_class_title ? enrollment.academic_class_title : "NA" }} </span>
                            </v-col>
                            <v-col cols="12" v-if=" enrollment.academic_class_start_date">
                                <strong>Assigned class start from</strong>
                                <span class=""> {{ enrollment.academic_class_start_date ? enrollment.academic_class_start_date : "NA" }} </span>
                            </v-col>
                            <v-col cols="12" v-if=" enrollment.orientation_date">
                                <strong>Date of orientation</strong>
                                <span class=""> {{ enrollment.orientation_date ? enrollment.orientation_date : "NA" }} </span>
                            </v-col>
                            <v-col cols="12" v-if=" enrollment.orientation_date">
                                <strong>Orientation invitation mail sent</strong>
                                <span class=""> {{ enrollment.is_orientation_notified ? "Yes" : "No" }} </span>
                            </v-col>
                            <v-col cols="12" v-if=" enrollment.formated_orientation_notified_at">
                                <strong>Orientation invitation mail sent</strong>
                                <span class=""> {{ enrollment.formated_orientation_notified_at }} </span>
                            </v-col>
                          <hr v-if="enrollment.admission_date">
                          <v-col cols="12" v-if=" enrollment.admission_date">
                            <strong>Date of admission</strong>
                            <span class=""> {{ enrollment.admission_date }} </span>
                          </v-col>
                        </v-col>
                        <v-col md="6">
                            <v-col cols="12">
                                <h5>Progress & status</h5><hr/>
                            </v-col>
                            <v-col cols="12" >
                                <div class="text-secondary">
                         <span class="mt-3" v-if="enrollment.formatted_interview_date && enrollment.formatted_interview_time && enrollment.is_approved">
                        <strong class="font-weight-medium">Interview date</strong>
                        {{ enrollment.formatted_interview_date ? enrollment.formatted_interview_date : 'NA' }}
                        {{ enrollment.formatted_interview_time ? enrollment.formatted_interview_time : '' }}
                      </span>

                                    <span class="font-weight-medium" v-if="!enrollment.formatted_interview_date && !enrollment.formatted_interview_time && enrollment.is_approved">
                       <v-chip small class="ma-2" color="orange" text-color="white">
                        <i class="fa fa-calendar-alt mr-2 text-white"></i>Interview not scheduled
                       </v-chip>
                      </span>
                                </div>

                                <div class="text-secondary" v-if="enrollment.admit_card_sent_in_mail">
                                    <v-chip small
                                            class="ma-2"
                                            color="green"
                                            text-color="white"
                                    >
                                        <i class="fa fa-id-badge mr-2 text-white"></i> Admit card emailed
                                    </v-chip>
                                </div>
                                <div class="text-secondary" v-if="enrollment.invoice_sent_in_mail">
                                    <v-chip small
                                            class="ma-2"
                                            color="green"
                                            text-color="white"
                                    >
                                        <i class="fa fa-dollar-sign mr-2 text-white"></i>Invoice emailed
                                    </v-chip>
                                </div>
                                <div class="text-secondary" style="margin-top: 9px;" v-if="enrollment.is_submitted">
                      <span v-if="enrollment.is_paid">
                         <v-chip small
                                 class="ma-2"
                                 color="green"
                                 text-color="white"
                         >
                             <i class="fa fa-dollar-sign mr-2 text-white"></i> Payment complete
                          </v-chip>
                      </span>
                                    <span v-if="!enrollment.is_paid" >
                         <v-chip small
                                 class="ma-2"
                                 color="orange"
                                 text-color="white"
                         >
                              <i class="fa fa-dollar-sign mr-2 text-white"></i> Payment pending
                          </v-chip>
                      </span>
                                </div>
                            </v-col>
                        </v-col>
                    </v-row>


                </v-row>

<!--                <v-row>-->
<!--                  <v-col cols="12">-->
<!--                    <h5>Facilities</h5><hr/>-->
<!--                  </v-col>-->
<!--                </v-row>-->

<!--                &lt;!&ndash;             <pre>{{enrollment_details.other_information}}</pre>&ndash;&gt;-->
<!--                <v-row v-if="enrollment_details && enrollment_details.other_information">-->
<!--                  <v-col cols="4">-->
<!--                    Hostel facility-->
<!--                    <span class="badge badge-primary"-->
<!--                          v-if="enrollment_details.other_information && enrollment_details.other_information.hostel_facility"-->
<!--                          :class="enrollment_details.other_information.hostel_facility && enrollment_details.other_information.hostel_facility.toLowerCase() == 'yes'?'badge-success':'badge-warning'">-->
<!--                     <strong class="text-capitalize">{{-->
<!--                         enrollment_details.other_information.hostel_facility.toLowerCase() == 'yes' ? "YES" : "NO"-->
<!--                       }}</strong>-->
<!--                  </span>-->
<!--                  </v-col>-->
<!--                  <v-col cols="4">-->
<!--                    Transport facility-->
<!--                    <span class="badge badge-primary"-->
<!--                          v-if="enrollment_details.other_information && enrollment_details.other_information.transportation_facility"-->
<!--                          :class="enrollment_details.other_information.transportation_facility && enrollment_details.other_information.transportation_facility.toLowerCase() == 'yes'?'badge-success':'badge-warning'">-->
<!--                     <strong class="text-capitalize">{{-->
<!--                         enrollment_details.other_information.transportation_facility.toLowerCase() == 'yes' ? "YES" : "NO"-->
<!--                       }}</strong>-->
<!--                  </span>-->
<!--                    &lt;!&ndash;                    <pre>{{enrollment_details.other_information}}</pre>&ndash;&gt;-->

<!--                  </v-col>-->
<!--                  <v-col cols="4"-->
<!--                         v-if="enrollment_details.other_information && enrollment_details.other_information.transportation_facility && enrollment_details.other_information.transportation_facility.toLowerCase() == 'yes' && enrollment_details.other_information.pickup_location">-->
<!--                    <span class="badge  badge-primary text-capitalize font-size-lg">-->
<!--                      {{ enrollment_details.other_information.pickup_location }}-->
<!--                    </span>-->
<!--                  </v-col>-->


<!--                </v-row>-->




              </v-card>
            </v-tab-item>
            <v-tab-item>
                  <v-card>
                    <v-row class="row bg-highlight-title" v-if="document_loading">
                      <div class="col-md-12 text-center">
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            color="purple"
                            indeterminate
                        ></v-progress-circular>
                      </div>

                    </v-row>
                      <v-row class="bg-highlight-title" v-if="!document_loading">
                          <v-col cols="12"><h5>Documents</h5></v-col>
                          <v-col cols="3" v-if="marksheet && marksheet.path">
                              <img :src="getImageType(marksheet.path)"
                                      alt style="width:250px; height:200px;cursor: pointer"
                                      @click="openImage(marksheet.path)"
                                      class="img-thumbnail"/>
                            <h5 class="text-center mt-3">Mark Sheet &nbsp; <span @click="deleteDocument(marksheet)"><i style="color: red" class="fas fa-trash"></i></span></h5>

                          </v-col>
                          <v-col cols="3" v-for="(document,index) in user_documents " :key="index" v-show="user_documents && user_documents.length>0">
                              <img style="width:250px; height:200px;cursor: pointer"
                                   :src="getImageType(document.path)"
                                   alt
                                   @click="openImage(document.path)"
                                   class="img-thumbnail"
                              />
                              <h5 class="text-center mt-3">{{ document.file_name }}
                                &nbsp;
                                <span @click="deleteDocument(document)"><i style="color: red" class="fas fa-trash"></i></span>
                              </h5>
                          </v-col>
                          <v-col v-if="user_documents.length == 0 && marksheet">
                              No additional document available
                          </v-col>
                      </v-row>
                  </v-card>
              </v-tab-item>
              <v-tab-item>
                  <v-card>
                      <v-row class="bg-highlight-title">
                          <v-col cols="12"><h5>Other information</h5></v-col>
                          <v-col cols="12" >
                              <strong>Hostel facility </strong>
                              <span class=""> : {{ other_information && other_information.hostel_facility ? 'Yes' :' No' }} </span>
                          </v-col>
                          <v-col cols="12" >
                              <strong>Transportation facility </strong>
                              <span class=""> : {{ other_information && other_information.transportation_facility ? 'Yes' :' No' }} </span>
                          </v-col>
                          <v-col cols="12" v-if="other_information && other_information.pickup_location">
                              <strong>Pickup location </strong>
                              <span class=""> : {{ other_information && other_information.pickup_location ? other_information.pickup_location :' N/A' }} </span>
                          </v-col>
                          <v-col cols="12" v-if="other_information && other_information.recomended_sources && other_information.recomended_sources.length > 0">
                              <strong>Recommended sources :</strong>

                              <span class="" >
                                <span class="ml-3 badge badge-primary" v-for="(recomended_source,index) in other_information.recomended_sources" :key="index">{{recomended_source}}</span>
                              </span>
                          </v-col>
                      </v-row>
                  </v-card>
              </v-tab-item>
              <v-tab-item v-if="enrollment.is_paid && enrollment.is_submitted && enrollment.is_approved && enrollment.invoice_number">
                  <v-card>
                      <v-row class="bg-highlight-title">
                          <v-col cols="12"><h5>Invoice</h5></v-col>
                          <v-col cols="12" >
                              <strong>Invoice #</strong>
                              <span class=" "> : {{ enrollment.invoice_number }} </span>
                          </v-col>
                          <v-col cols="12" >
                              <strong>Payment method</strong>
                              <span class=" "> : {{ ucwords(enrollment.payment_method) }} </span>
                          </v-col>
                          <v-col cols="12" >
                              <strong>Remarks :</strong>
                              <p v-html="enrollment.remarks">  </p>
                          </v-col>
                          <v-col cols="12" >
                              <strong>Sub total </strong>
                              <span class=" "> : Rs {{ enrollment.invoice_sub_total || 0 }} </span>
                          </v-col>
                          <v-col cols="12" >
                              <strong>Tax</strong>
                              <span class=" "> : Rs {{ enrollment.invoice_tax_total || 0 }} </span>
                          </v-col>
                          <v-col cols="12" >
                              <strong>Total</strong>
                              <span class=" "> : Rs {{ enrollment.invoice_sub_total + enrollment.invoice_tax_total }}</span>
                          </v-col>
                          <v-col cols="12" v-if="checkIsAccessible('pre-registration', 'preview-invoice') && enrollment.entrance_exam_id && enrollment.is_paid">

                              <v-btn color="blue-grey" @click="previewInvoice"
                                      class="ma-2 white--text">
                                  Preview invoice
                                  <v-icon right dark>
                                     fas fa-file-invoice
                                  </v-icon>
                              </v-btn>
                          </v-col>
                      </v-row>
                  </v-card>
              </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import UserDocumentService from "@/core/services/user/UserDocumentService";
import {API_URL} from "@/core/config";

const enrollmentService = new UserEnrollmentService();
const userDocument=new UserDocumentService();
export default {
  name: "Detail",
    data() {
    return {
      dialog:false,
      isLoading: false,
      attrs:null,
      enrollment:null,
      enrollment_details:null,
      documents: [],
      user_documents: [],
      edit_mode: false,
      photo: null,
      marksheet: {
        path:null,
        id:null,
      },
      basic_info:null,
      educational_information:null,
      other_information:null,
      shifts:null,
      document_loading:false,
    }
  },
  methods: {
      previewInvoice() {
          if(this.enrollment && this.enrollment.id){
              let url = `${API_URL}user/pre-registration/${this.enrollment.id}/preview/invoice`
              window.open(url, "_blank")
          }

      },
      ucwords(value) {
          if (!value) return ''
          return value.toString().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
      },
    openDialog(){
      this.dialog=true;
    },
    closeDialog() {
      this.documents = [];
      this.user_documents = [];
      this.photo = null;
      this.marksheet = null;
      this.dialog=false;
      this.enrollment=null;
      this.basic_info=null;
      this.shifts=null;
      this.other_information=null;
      this.educational_information=null;
      this.enrollment_details=null;
      this.marksheet= {
        path:null,
        id:null,
      };
    },
    enableEditMode() {
      this.edit_mode = !this.edit_mode;
    },
    enrollmentDetail(enrollment) {

      this.enrollment = enrollment;
      if(this.enrollment){
          this.openDialog();
        this.getDocuments(this.enrollment);
        if(this.enrollment.more_details){
          this.enrollment_details = JSON.parse(enrollment.more_details)
          if(this.enrollment_details && this.enrollment_details.basic_info){
            this.basic_info=this.enrollment_details.basic_info;
          }
          if(this.enrollment_details && this.enrollment_details.educational_information){
            this.educational_information=this.enrollment_details.educational_information;
            if(this.educational_information && this.educational_information.shifts){
              this.shifts=this.educational_information.shifts;
            }else if(this.educational_information && this.educational_information.shift){
              this.shifts=this.educational_information.shift;
            }else{
              this.shifts='N/A';
            }
          }
          if(this.enrollment_details && this.enrollment_details.other_information){
            this.other_information=this.enrollment_details.other_information;
          }

        }

        if (this.enrollment.submission_type == "offline") {
          if (this.enrollment_details.other_information && this.enrollment_details.other_information.additional_documents) {
            this.documents = this.enrollment_details.other_information.additional_documents;
          }
        }
      }

    },
    enrollmentDocuments(documents) {
      this.documents = [];
      this.user_documents=[];
      this.photo=null;
      this.marksheet={
        id:null,
        path:null,
      };
      this.documents = documents;

      if (this.documents && this.documents.length > 0) {
        this.documents.forEach(ele => {
          if (ele.type && ele.type.toLowerCase() == "photo") {
            this.photo = ele.image_path['real']
          } else if (ele.type && ele.type.toLowerCase() == "marksheet") {
            this.marksheet.path = ele.image_path['real'];
            this.marksheet.id = ele.id;
          } else {
            this.user_documents.push({id:ele.id,path: ele.image_path.real, file_name: ele.type})
          }
        })
      }
    },
    getImageType(image) {
      let file = null
      if (image.includes('.pdf')) {
        file = "/media/svg/files/pdf.svg";
      } else if (image.includes('.csv') || image.includes('.xlsx')) {
        file = "/media/svg/files/csv.svg";
      } else if (image.includes('.docx')) {
        file = "/media/svg/files/doc.svg";
      } else {
        file = image;
      }
      return file;
    },
    openImage(path) {
      window.open(path, "_blank")
    },
    deleteDocument(document){
      if(document && this.enrollment && this.enrollment.id){
        this.$confirm({
          message: `Are you sure?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          callback: confirm => {
            if (confirm) {
              this.document_loading=true;
              userDocument
                  .delete(this.enrollment.id,document.id)
                  .then(response => {
                    this.getDocuments(this.enrollment);
                    this.$snotify.success("Document has been deleted successfully");
                  })
                  .catch(error => {
                    this.$snotify.error("Something went wrong. Please try again later")
                  })
                  .finally(() => {
                    this.document_loading=false;
                  });
            }
          }
        });
      }

    },
    updateEnrollment() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.$emit('update_enrollment', this.enrollment)
          }
        }
      });
    },
    getDocuments(enrollment) {
      this.document_loading=true;
      userDocument
          .paginate(enrollment.id)
          .then(response => {
            let documents=  response.data.data;
            if(documents.length > 0){
              this.enrollmentDocuments(response.data.data)
            }

          })
          .catch(error => {
            //console.log(error);
          })
          .finally(() => {
            this.document_loading=false;
          });
    },
  }
}
</script>

<style scoped>
.badge-compulsary-course{
    background-color:#6c2a9c;
    color: #fff;
    padding: 0.4em 0.6em;
    border-radius: 0.25rem;
}
.form-label {
    font-size: 15px;
    float: left;
    width: 250px;
    font-weight: 500;
}
.form-control {
    margin-left: 30px;
    font-size: 15px;
    float: left;
    min-width: 70%;
    border: none;

    background-color: transparent;
    outline: none;
    width: 500px;
}
</style>