<template>
  <v-dialog v-model="dialog" persistent max-width="600px">

    <v-card>

      <v-toolbar dark>
        <v-card-title class="headline">
          Assign interview date
          <hr>
        </v-card-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon  @click="closeDialog">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>

        <v-row>
          <v-col cols="12">
            <v-menu
                ref="menu"
                v-model="date_picker"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"

            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="interview_date"
                    label="Interview date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on" clearable
                    outlined dense :error="$v.interview_date.$error"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-if="date_picker"
                  v-model="interview_date"
                  full-width
                  :min="current_date"

              ></v-date-picker>
            </v-menu>

            <span class="text-danger" v-if="$v.interview_date.$error">This information is required</span>
          </v-col>
          <v-col cols="12">

            <v-text-field
                v-model="interview_time"
                label="Interview time"
                prepend-inner-icon="mdi-calendar"
                type="time"

                clearable
                outlined dense :error="$v.interview_time.$error"
            ></v-text-field>
            <span class="text-danger" v-if="$v.interview_time.$error">This information is required</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn btn-standard cancel-btn" @click="closeDialog" depressed>Cancel</v-btn>
        <v-btn class="btn btn-primary text-white" depressed @click.prevent="validateInterviewTime">Set interview date
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";

const enrollmentService = new UserEnrollmentService();
export default {
  name: "SetInterView",
  props: ['dialog'],
  validations: {
    interview_date: {required},
    interview_time: {required},
  },
  data() {
    return {
      selected_enrolment: [],
      interview_date: null,
      interview_time: null,
      time_picker: false,
      date_picker: false,
    }
  }, computed: {
    current_date() {
      return this.$moment().format("YYYY-MM-DD")
    }, current_time() {
      return this.$moment().format('h:mm')
    }
  }, methods: {
    validateInterviewTime() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 5000)
      } else {
        this.setInterviewDate();
      }
    }, closeDialog() {
      this.selected_enrolment = []
      this.interview_date = null
      this.interview_time = null
      this.$emit("close_interview_dialog")
    }, setSelectedCandidate(selected_enrolment) {
      this.selected_enrolment = selected_enrolment
    },
    setInterviewDate() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            let data = {
              selected_enrolment: this.selected_enrolment,
              interview_date: this.interview_date,
              interview_time: this.interview_time
            }
            enrollmentService.setInterviewDate(data).then(response => {
              this.displayMessage('success')
              this.closeDialog();

            }).catch(error => {
              this.displayMessage('error')
            })
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>