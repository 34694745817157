<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>
            <span>Assign classroom</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                  <v-select dense outlined label="Available classroom" :error="$v.enrollment.assigned_class_id.$error" :items="academic_classes" item-text="customize_title" item-value="id" v-model="enrollment.assigned_class_id"></v-select>
                  <span class="text-danger" v-if="$v.enrollment.assigned_class_id.$error">This information is required.</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                      text
                      class="cancel-btn"
                      x-large
                      @click="closeDialog">
                  Cancel
              </v-btn>
              <v-btn
                      color="btn btn-primary"
                      dark
                      x-large
                      :loading="loading"
                      @click="save()"
              >
                  Save
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script >
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";

import {required, email, requiredIf} from "vuelidate/lib/validators";

const userEnrollment=new UserEnrollmentService();
export default {
    validations: {
        enrollment:{
            assigned_class_id:{required},
        }

    },
    data(){
        return{
            dialog:false,
            enrollmentId:null,
            enrollment:{
                assigned_class_id:null,
            },
            loading:false,
            classroom_loading:false,
            academic_classes:[],
        }
    },
    methods:{
        closeDialog(){
            this.dialog=false;
            this.resetData();
        },
        openDialog(){
            this.dialog=true;
        },
        resetData(){
            this.enrollmentId=null;
            this.enrollment= {
                assigned_class_id:null,
            };
            this.academic_classes=[];
        },
        assignClass(enrollmentId){
            this.enrollmentId=enrollmentId;
            this.openDialog();
            this.getAvailableClassrooms();
        },
        getAvailableClassrooms(){
            this.classroom_loading=true;
            userEnrollment
                .getAvailableClassrooms(this.enrollmentId)
                .then((response) => {
                  this.academic_classes=response.data.class_rooms;
                })
                .catch(err => {

                })
                .finally(() => (this.classroom_loading = false))
        },
        save(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                    this.$v.$reset()
                }, 3000);
            } else {
                this.loading = true;

                userEnrollment
                    .acceptEnrollmentOffer(this.enrollmentId, this.enrollment)
                    .then((response) => {
                        this.$snotify.success("Assign class successfully");
                        this.closeDialog();
                        this.$emit('refresh');
                    })
                    .catch(err => {

                    })
                    .finally(() => (this.loading = false))
            }
        }
    }

}
</script>