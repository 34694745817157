<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>
            <span>Enrolment offer accept</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <v-card-text>
              <v-container>
                  <v-row>
                      <v-col cols="12">
                          <v-menu
                                  v-model="enrolment_offer_accepted_menu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field dense outlined
                                          v-model="enrolment.enrolment_offer_accepted_datetime"
                                          label="Enrolment offer accepted date"
                                          prepend-inner-icon="mdi-calendar" readonly
                                          v-bind="attrs"
                                          v-on="on"
                                  ></v-text-field>
                              </template>
                              <v-date-picker
                                      v-model="enrolment.enrolment_offer_accepted_datetime"
                                      @input="enrolment_offer_accepted_menu = false"
                              ></v-date-picker>
                          </v-menu>
                          <span class="text-danger" v-if="$v.enrolment.enrolment_offer_accepted_datetime.$error">This information is required.</span>
                      </v-col>
                  </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text class="cancel-btn" x-large
                      @click="closeDialog">Cancel
              </v-btn>
              <v-btn color="btn btn-primary" dark
                      x-large
                      :loading="loading"
                      @click="acceptEnrolmentOffer()">
                  Save
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
const userEnrollment=new UserEnrollmentService();
export default {
    validations: {
        enrolment:{
            enrolment_offer_accepted_datetime:{required},
        },
    },
    data(){
        return{
            loading:false,
            dialog:false,
            enrolment_offer_accepted_menu:false,
            enrollmentId:null,
            enrolment:{
                enrolment_offer_accepted:1,
                enrolment_offer_accepted_datetime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            }
        }
    },
    methods:{
        closeDialog(){
          this.dialog=false;
        },
        openDialog(){
            this.dialog=true;
        },
        resetData(){
            this.enrollmentId=null;

        },
        enrolmentOfferAccept(enrolmentId){
            this.enrollmentId=enrolmentId;
            this.openDialog();
        },
        acceptEnrolmentOffer(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                    this.$v.$reset()
                }, 3000);
            } else {
                this.loading = true;

                userEnrollment
                    .acceptEnrollmentOffer(this.enrollmentId, this.enrolment)
                    .then((response) => {
                        this.$snotify.success("Information updated successfully");
                        this.closeDialog();
                        this.$emit('refresh');
                    })
                    .catch(err => {

                    })
                    .finally(() => (this.loading = false))
            }
        },
    }
}

</script>