<template>
  <v-dialog v-model="dialog" persistent max-width="600px">

    <v-card>
      <v-toolbar dark>
        <v-card-title class="headline">
          Print bulk {{job_type && job_type == 'offer_letter' ? 'offer letter' : 'admit card' }} documents
          <hr>
        </v-card-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon  @click="closeDialog">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="11">
              <v-text-field v-model="printing.title"
                            placeholder="Title"
                            outlined
                            dense
                            clearable>
              </v-text-field>
            </v-col>

            <v-col cols="11">
              <v-select
                  v-model="printing.printer_id"
                  :items="printers"
                  item-value="id"
                  item-text="title"
                  label="Select printer"
                  outlined
                  dense
                  clearable
                  :error="$v.printing.printer_id.$error"
              ></v-select>
              <span class="text-danger" v-if="$v.printing.printer_id.$error">This information is required</span>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn btn-standard cancel-btn" @click="closeDialog" depressed>
          Cancel
        </v-btn>
        <v-btn  class="text-white ml-2 btn btn-primary"
                depressed @click.prevent="printSelected()">
          Print selected
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import PrinterService from "@/core/services/printer/PrinterService";
import {required} from "vuelidate/lib/validators";

const enrollmentService = new UserEnrollmentService();
const printerService = new PrinterService();
export default {
  name: "print",
  props: ['dialog', 'selected','job_type'],
  validations: {
    printing:{
      printer_id: {required},
    }
  },
  data() {
    return {
      printing:{
        title:null,
        printer_id:null,
        job_type: [],
      },
      printers:[],

    }
  },
  mounted() {
   this.getPrinters();
  },
  methods: {
    closeDialog() {
      this.printing={
        title:null,
        printer_id:'',
        job_type: [],
      };
      this.$emit("close_dialog")
    },
    getPrinters() {
      printerService
          .paginate()
          .then(response => {
            this.printers = response.data.data;
      });
    },
    printSelected(){
    if(this.job_type){
      this.printing.job_type.push(this.job_type)
    }
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 5000)
      } else {
        this.$confirm({
          message: `Are you sure you want to print selected from list?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              let data = {ids: this.selected, title:this.printing.title, job_type:this.printing.job_type,printer_id:this.printing.printer_id}
              enrollmentService.initiatePrintJob(data).then(response => {
                this.closeDialog();
                this.displayMessage("success")
              }).catch(error => {
                this.displayMessage("error")
              })
            }
          }
        });
      }

    }
  }
}
</script>

<style scoped>

</style>