import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";

const enrollmentService = new UserEnrollmentService();
const EnrollmentMixin = {

    data() {
        return {
            enrollments: [],
            selected_candidates: [],
            selected_candidate_list: [],
        }
    },

    methods: {
        // getEnrollments() {
        //     enrollmentService
        //         .paginate(this.search, this.page)
        //         .then(response => {
        //             this.enrollments = response.data.data;
        //             this.page = response.data.meta.current_page;
        //             this.total = response.data.meta.total;
        //             this.perPage = response.data.meta.per_page;
        //         })
        //         .catch((err) => {
        //             // console.log(err)
        //         }).finally(() => {
        //     });
        // },
        getEnrollmentCandidates() {
            this.search.date = this.entrance.scheduled_for;
            this.search.start_time = this.entrance.start_time;
            this.search.end_time = this.entrance.end_time;

            enrollmentService
                .getEnrollmentCandidates(this.search)
                .then(response => {
                    this.enrollments = response.data.enrollments
                })
                .catch((err) => {
                    // console.log(err)
                }).finally(() => {
            });
        },
        getSelectedCandidate(entranceId) {
            this.$bus.emit('toggleLoader');
            enrollmentService
                .selectedCandidate(entranceId)
                .then(response => {
                    this.$bus.emit('toggleLoader');
                    this.selected_candidates = response.data.enrollments
                })
                .catch((err) => {
                    this.$bus.emit('toggleLoader');
                    // console.log(err)
                }).finally(() => {
            });
        }, updateEnrollment(id, data) {
            this.$bus.emit('toggleLoader');
            enrollmentService
                .updateEnrollmentUser(id, data)
                .then(response => {
                    this.$bus.emit('toggleLoader');
                    this.getEntrance()
                })
                .catch((err) => {
                    // console.log(err)
                }).finally(() => {
            });
        }
    }
}
export default EnrollmentMixin;