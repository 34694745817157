<template>
  <v-app>
      <v-dialog v-model="dialog" persistent max-width="1000px">
          <v-card>
              <v-toolbar dark>
                  <v-card-title class="headline">
                     Manage entrance room
                      <hr />
                  </v-card-title>

                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="closeDialog">
                          <i class="fas fa-times"></i>
                      </v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-card-text v-if="!enrollmentLoading">
                  <v-row>
                      <v-col cols="6">
                          <v-text-field dense outlined v-model="enrollment.building_desc" label="Building" :error="$v.enrollment.building_desc.$error">
                          </v-text-field>
                          <span class="text-danger" v-if="$v.enrollment.building_desc.$error">This information is required.</span>
                      </v-col>
                      <v-col cols="6">
                          <v-text-field dense outlined v-model="enrollment.room_desc" label="Room" :error="$v.enrollment.room_desc.$error"></v-text-field>
                          <span class="text-danger" v-if="$v.enrollment.room_desc.$error">This information is required.</span>
                      </v-col>
                  </v-row>
                  <v-row v-show="enrollments.length > 0">
                      <v-checkbox class="id-card__checkbox" @change="selectAll()" v-model="checkAll" label="Select all"></v-checkbox><hr/>
                      <div class="col-md-4" v-for="(item, index) in enrollments" :key="index">
                          <v-checkbox v-model="enrollment.ids"
                                      :value="item.id" :label="item.full_name +' ('+item.symbol_no+')'">
                          </v-checkbox>
                      </div>
                  </v-row>
                  <v-row v-if="enrollments.length == 0">
                    <v-col md="12" class="text-center">
                      <span class="font-weight-bold">
                        No candidates found.
                      </span>
                    </v-col>
                  </v-row>

              </v-card-text>
              <v-card-text v-if="enrollmentLoading">
                <v-row>
                  <v-col md="12" class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                  </v-col>
                </v-row>

              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          text
                          class="cancel-btn"
                          x-large
                          @click="closeDialog">
                      Cancel
                  </v-btn>
                  <v-btn
                          color="btn btn-primary"
                          dark
                          x-large
                          :loading="loading "
                          @click="save()"
                          v-if="enrollment.ids.length > 0"
                  >
                      Save
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
const userEnrollment=new UserEnrollmentService();

export default {
    validations: {
        enrollment:{
            building_desc:{required},
            room_desc:{required},
        }
    },
    data(){
        return{
            dialog:false,
            loading:false,
            enrollments:[],
            enrollment:{
                ids:[],
                building_desc:null,
                room_desc:null,
            },
            checkAll:false,
          enrollmentLoading:false,
        }
    },
    methods:{
        closeDialog(){
            this.dialog=false;
            this.resetData();
        },
        resetData(){
          this.enrollments=[];
          this.enrollment={
              ids:[],
              building_desc:null,
              room_desc:null,
          }
          this.checkAll=false;
          this.$v.$reset();
          this.enrollmentLoading=false;
        },
        selectAll() {
            if (!this.checkAll) {
                this.enrollment.ids = [];
            } else {
                this.enrollments.forEach(user => {
                    this.enrollment.ids.push(user.id);
                })

            }
        },
        openDialog(){
            this.dialog=true;
        },
        manageEntranceRooms(entranceId) {
            this.openDialog();
            if(entranceId){
              this.getAllCandidateByEntranceExamId(entranceId);
            }
        },
        getAllCandidateByEntranceExamId(entranceId){
          this.enrollmentLoading=true;
          userEnrollment
              .selectedCandidate(entranceId)
              .then((response) => {
                this.enrollments=response.data.enrollments;
              })
              .catch(err => {

              })
              .finally(() => (
                  this.enrollmentLoading = false
              ))
        },
        save(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                    this.$v.$reset()
                }, 3000);
            } else {
                userEnrollment
                    .manageBulkEntranceRoom(this.enrollment)
                    .then((response) => {
                        this.$snotify.success("Assign room successfully");
                        this.closeDialog();
                        this.$emit('refresh');
                    })
                    .catch(err => {

                    })
                    .finally(() => (this.loading = false))
            }
        },
    },
    mounted() {

    }
}
</script>