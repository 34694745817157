<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>
            <span>Enter mark for {{enrollment? enrollment.full_name:''}}</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="obtained_marks" :error="$v.obtained_marks.$error" dense outlined label="Obtained marks">
                </v-text-field>
                <span class="text-danger" v-if="$v.obtained_marks.$error">This information is required.</span>
              </v-col>
              <v-col cols="12">
                <v-text-field dense outlined v-model="scholarship" label="Scholarship"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea dense outlined v-model="scholarship_remarks" placeholder="Scholarship"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
              color="btn btn-primary"
              dark
              x-large
              :loading="loading"
              @click="updateMarks()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import {required, email, requiredIf} from "vuelidate/lib/validators";
const userEnrollment=new UserEnrollmentService();
export default {
  validations: {
    obtained_marks:{required},
  },
  data(){
    return{
      dialog:false,
      enrollment:null,
      enrollmentId:null,
      obtained_marks:null,
      scholarship:null,
      scholarship_remarks:null,
      loading:false,
    }
  },
  methods:{
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    resetData(){
      this.enrollmentId=null;
      this.obtained_marks=null;
      this.scholarship=null;
      this.scholarship_remarks=null;
    },
    updateUserEnrollmentResult(enrollment,obtainedMark){
      this.enrollment = enrollment;
      this.enrollmentId=enrollment.id;
      this.obtained_marks=obtainedMark;
      this.scholarship_remarks=enrollment.scholarship_remarks;
      this.scholarship=enrollment.scholarship;
      this.openDialog();
    },
    updateMarks(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        let data = {'obtained_marks': this.obtained_marks,'scholarship':this.scholarship,'scholarship_remarks':this.scholarship_remarks};
        userEnrollment
            .updateMarks(this.enrollmentId, data)
            .then((response) => {
              this.$snotify.success("Result updated successfully");
              this.closeDialog();
              this.$emit('refresh');
            })
            .catch(err => {

            })
            .finally(() => (this.loading = false))
      }
    }

  }
}
</script>