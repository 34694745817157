<template>
  <v-dialog v-model="dialog" persistent max-width="600px">

    <v-card>
      <v-toolbar dark>
        <v-card-title class="headline">
          Re-schedule entrance date & time
          <hr>
        </v-card-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon  @click="closeDialog">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
<!--        <pre>{{ selected_entrance_date }}</pre>-->
<!--        <pre>{{ selected_entrance_time }}</pre>-->
<!--        <pre>{{ enrollment_id }}</pre>-->
        <v-row>
          <div class="col-6">
            <v-menu
                v-model="menu5"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :error="$v.selected_entrance_date.$error"
                    v-model="selected_entrance_date"
                    label="Entrance date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined dense
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="selected_entrance_date"
                  @input="menu5 = false"
              ></v-date-picker>
            </v-menu>
            <span class="text-danger" v-if="$v.selected_entrance_date.$error">This information is required</span>
          </div>
          <div class="col-6">
            <v-menu
                ref="menu"
                v-model="menu4"
                :close-on-content-click="false"
                :nudge-right="40"

                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense
                              v-model="selected_entrance_time"
                              label="Entrance time"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error="$v.selected_entrance_time.$error"
                >
                </v-text-field>
              </template>
              <v-time-picker
                  v-if="menu4"
                  v-model="selected_entrance_time"
                  full-width
                  @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
            <span class="text-danger" v-if="$v.selected_entrance_time.$error">This information is required</span>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn btn-standard cancel-btn" @click="closeDialog" depressed>Cancel</v-btn>
        <v-btn class="btn btn-primary text-white" depressed @click.prevent="validateEntranceForm">Re-schedule entrance date
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";

const enrollmentService = new UserEnrollmentService();
export default {
  name: "EntranceDate.vue",
  props: ['dialog', 'enrollment_id'],
  validations: {
    selected_entrance_date: {required},
    selected_entrance_time: {required},
  },
  data() {
    return {
      selected_enrolment: [],
      selected_entrance_date: null,
      selected_entrance_time: null,
      time_picker: false,
      date_picker: false,
      menu5: false,
      menu4: false,
    }
  }, computed: {
    current_date() {
      return this.$moment().format("YYYY-MM-DD")
    }, current_time() {
      return this.$moment().format('h:mm')
    }
  }, methods: {
    validateEntranceForm() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 5000)
      } else {
        this.setEntranceDateAndTime();
      }
    }, closeDialog() {
      this.selected_entrance_date = null
      this.selected_entrance_time = null
      this.$emit("close_entrance_dialog")
    }, setSelectedCandidate(selected_enrolment) {
      this.selected_enrolment = selected_enrolment
    },entranceDateAndTimeSet(date, time){
      this.selected_entrance_date = date;
      this.selected_entrance_time =  time;
    },
    setEntranceDateAndTime() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            let data = {
              enrollment_id: this.enrollment_id,
              selected_entrance_date: this.selected_entrance_date,
              selected_entrance_time: this.selected_entrance_time
            }
            enrollmentService.setEntranceDateAndTime(data).then(response => {
              this.displayMessage('success')
              this.closeDialog();

            }).catch(error => {
              this.displayMessage('error')
            })
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>