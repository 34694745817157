<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="headline">
          Mark as admitted
          <hr>
        </v-card-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon  @click="closeDialog">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="enrolment.admission_remarks" label="Admission remarks" :error="$v.enrolment.admission_remarks.$error" dense outlined>

              </v-textarea>
              <span class="text-danger" v-if="$v.enrolment.admission_remarks.$error">This information is required</span>
            </v-col>

        </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="cancel-btn" x-large
               @click="closeDialog">Cancel
        </v-btn>
        <v-btn color="btn btn-primary" dark
               x-large
               :loading="loading"
               @click="save()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
const userEnrollment=new UserEnrollmentService();
export default {
  validations: {
    enrolment:{
      admission_remarks:{required}
    },
  },
  data(){
    return{
      loading:false,
      dialog:false,
      menu:false,
      enrollmentId:null,
      enrolment:{
        is_admitted:1,
        admission_remarks:null,

      }
    }
  },
  methods:{
    closeDialog(){
      this.dialog=false;
      this.enrolment.admission_remarks=null;
      this.$v.$reset();
    },
    openDialog(){
      this.dialog=true;
    },
    markAsAdmitted(enrolmentId){
      if(enrolmentId){
        this.openDialog();
        this.enrollmentId=enrolmentId;
      }

    },
    save(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.loading = true;
        userEnrollment
            .markEnrollmentAdmitted(this.enrollmentId,this.enrolment)
            .then((response) => {
              this.$snotify.success("Information updated successfully");
              this.closeDialog();
              this.$emit('refresh');
              this.enrolment.admission_remarks=null;
            })
            .catch(err => {

            })
            .finally(() => (this.loading = false))
      }
    },
  },
  mounted() {

  }
}
</script>